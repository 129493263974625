export const optionApply = [
  { value: 'Free Agent - Founder', label: 'Founder' },
  { value: 'Free Agent - Operator', label: 'Fulltime Roles' },
  { value: 'Free Agent - Angel Investor', label: 'Angel Investor' },
  { value: 'Free Agent - Advisor', label: 'Advisor' },
  { value: 'Free Agent - Mentor', label: 'Mentor' },
];
export const optionExpertise = [
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Product', label: 'Product' },
  { value: 'Business Development', label: 'Business Development' },
  { value: 'Business Operations', label: 'Business Operations' },
  { value: 'CEO', label: 'CEO' },
  { value: 'Communications', label: 'Communications' },
  { value: 'Corporate Development', label: 'Corporate Development' },
  { value: 'Customer Service', label: 'Customer Service' },
  { value: 'Customer Success', label: 'Customer Success' },
  { value: 'Data Science', label: 'Data Science' },
  { value: 'Design', label: 'Design' },
  { value: 'Education', label: 'Education' },
  { value: 'Entrepreneurship', label: 'Entrepreneurship' },
  { value: 'Finance', label: 'Finance' },
  { value: 'General Management', label: 'General Management' },
  { value: 'Growth', label: 'Growth' },
  { value: 'Information Technology', label: 'Information Technology' },
  { value: 'Investment', label: 'Investment' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Management Consulting', label: 'Management Consulting' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Medicine', label: 'Medicine' },
  { value: 'Military', label: 'Military' },
  { value: 'Operations', label: 'Operations' },
  { value: 'People', label: 'HR' },
  { value: 'Policy', label: 'Policy' },
  { value: 'Public Relations', label: '  Public Relations' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Research', label: 'Research' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Revenue', label: 'Revenue' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Science', label: 'Science' },
  { value: 'Security', label: 'Security' },
  { value: 'Strategy', label: 'Strategy' },
  { value: 'Supply Chain', label: 'Supply Chain' },
  { value: 'Talent', label: 'Talent' },
];
export const optionIndustry = [
  { value: 'AI', label: 'AI' },
  { value: 'Consumer', label: 'Consumer' },
  { value: 'Crypto', label: 'Crypto' },
  { value: 'Enterprise', label: 'Enterprise' },
  { value: 'FinTech', label: 'FinTech' },
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Global Resilience', label: 'Global Resilience' },
];
export const optionRegion = [
  { value: 'APAC', label: 'APAC' },
  { value: 'Boston', label: 'Boston' },
  { value: 'Central/Midwest', label: 'Central/Midwest' },
  { value: 'EMEA Other', label: 'EMEA Other' },
  { value: 'France', label: 'France' },
  { value: 'Germany', label: 'Germany' },
  { value: 'India', label: 'India' },
  { value: 'Israel', label: 'Israel' },
  { value: 'LATAM', label: 'LATAM' },
  { value: 'Mid-Atlantic', label: 'Mid-Atlantic' },
  { value: 'Mountain', label: 'Mountain' },
  { value: 'Netherlands', label: 'Netherlands' },
  { value: 'New York', label: 'New York' },
  { value: 'Nordic Countries', label: 'Nordic Countries' },
  { value: 'NORAM: Other', label: 'NORAM: Other' },
  { value: 'Northeast: Other', label: 'Northeast: Other' },
  { value: 'Pacific Northwest', label: 'Pacific Northwest' },
  { value: 'Remote', label: 'Remote' },
  { value: 'Silicon Valley', label: 'Silicon Valley' },
  { value: 'So. California', label: 'So. California' },
  { value: 'Southeast', label: 'Southeast' },
  { value: 'Southwest', label: 'Southwest' },
  { value: 'UK', label: 'UK' }
];

export const supportingCompany = [
  { value: 'Early (Series Seed-B)', label: 'Early (Series Seed-B)' },
  { value: 'Growth (Series B+)', label: 'Growth (Series B+)' },
  { value: 'Late (Pre-IPO)', label: 'Late (Pre-IPO)' },
];
export const ethnicity = [
  { value: 'Black', label: 'Black' },
  { value: 'East Asian', label: 'East Asian' },
  { value: 'I prefer not to say', label: 'I prefer not to say' },
  { value: 'Latinx', label: 'Latinx' },
  { value: 'Middle Eastern', label: 'Middle Eastern' },
  { value: 'Native American', label: 'Native American' },
  { value: 'Pacific Islander', label: 'Pacific Islander' },
  { value: 'South Asian', label: 'South Asian' },
  { value: 'Southeast Asian', label: 'Southeast Asian' },
  { value: 'White', label: 'White' },
];

export const gender = [
  { value: 'Female', label: 'Female' },
  { value: 'Male', label: 'Male' },
  { value: 'Non-binary', label: 'Non-binary' },
  { value: 'I prefer not to say', label: 'I prefer not to say' },
];

export const experience = [
  { value: '0-2', label: '0-2 years' },
  { value: '2-5', label: '2-5 years' },
  { value: '5-10', label: '5-10 years' },
  { value: '10+', label: 'More than 10 years' },
];

export const languages = [
  { value: 'English', label: 'English' },
  { value: 'French', label: 'French' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'German', label: 'German' },
  { value: 'Other', label: 'Other' },
];
